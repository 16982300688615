import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper kpmcbid0yxe-editor_css' },
  page: { className: 'home-page kpmcbh1y5km-editor_css' },
  logo: {
    className: 'header0-logo',
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/hk.png',
  },
  Menu: {
    className: 'header0-menu kpmcd0n1xy-editor_css',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#Banner5_0',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#Content3_0',
          children: [
            {
              children: (
                <span>
                  <p>解决方案</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item~kpnhfkqqh69',
        className: 'header0-item',
        children: {
          href: '#Content5_0',
          children: [
            {
              children: (
                <span>
                  <p>应用场景</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>产品中心</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            className: 'item-1 kpmbk7a2e6o-editor_css',
            children: {
              className: 'item-sub-item kpmbh6clu3p-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title kpmbjzfxxos-editor_css',
                  children: (
                    <span>
                      <p>EDC</p>
                    </span>
                  ),
                },
              ],
              href: '#Feature4_0',
            },
          },
          {
            className: 'item-2 kpmbnj3vem-editor_css',
            children: {
              className: 'item-sub-item kpmbh6clu3p-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title kpmbjzfxxos-editor_css',
                  children: (
                    <span>
                      <p>中央随机</p>
                    </span>
                  ),
                },
              ],
              href: '#Feature4_1',
            },
          },
          {
            className: 'item-3 kpmbnj3vem-editor_css',
            children: {
              className: 'item-sub-item kpmbh6clu3p-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title kpmbjzfxxos-editor_css',
                  children: (
                    <span>
                      <p>随访管理</p>
                    </span>
                  ),
                },
              ],
              href: '#Feature4_2',
            },
          },
          {
            className: 'item-3 kpmbnj3vem-editor_css',
            children: {
              className: 'item-sub-item kpmbh6clu3p-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title kpmbjzfxxos-editor_css',
                  children: (
                    <span>
                      <p>电子知情</p>
                    </span>
                  ),
                },
              ],
              href: '#Feature4_3',
            },
          },
          {
            className: 'item-3 kpmbnj3vem-editor_css',
            children: {
              className: 'item-sub-item kpmbh6clu3p-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title kpmbjzfxxos-editor_css',
                  children: (
                    <span>
                      <p>数据分析</p>
                    </span>
                  ),
                },
              ],
              href: '#Feature4_4',
            },
          },
          {
            className: 'item-3 kpmbnj3vem-editor_css',
            children: {
              className: 'item-sub-item kpmbh6clu3p-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title kpmbjzfxxos-editor_css',
                  children: (
                    <span>
                      <p>文档管理</p>
                    </span>
                  ),
                },
              ],
              href: '#Feature4_6',
            },
          },
          {
            className: 'item-3 kpmbnj3vem-editor_css',
            children: {
              className: 'item-sub-item kpmbh6clu3p-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title kpmbjzfxxos-editor_css',
                  children: (
                    <span>
                      <p>项目管理</p>
                    </span>
                  ),
                },
              ],
              href: '#Feature4_5',
            },
          },
          {
            className: 'item-3 kpmbnj3vem-editor_css',
            children: {
              className: 'item-sub-item kpmbh6clu3p-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title kpmbjzfxxos-editor_css',
                  children: (
                    <span>
                      <p>中心影像</p>
                    </span>
                  ),
                },
              ],
              href: '#Feature4_7',
            },
          },
        ],
      },
      {
        name: 'item~kpqesc2za0e',
        className: 'header0-item',
        children: {
          href: '#Content5_1',
          children: [
            {
              children: (
                <span>
                  <p>客户案例</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item~kprobhcy1f9',
        className: 'header0-item',
        children: {
          href: '#Feature2_0',
          children: [
            {
              children: (
                <span>
                  <p>关于我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: 'https://www.geekedc.net/index.html',
          children: [
            {
              children: (
                <span>
                  <p>开始使用</p>
                </span>
              ),
              name: 'text',
              className: 'kpmbgckcdib-editor_css',
            },
          ],
          target: '_blank',
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>极客医研通</p>
          </span>
        ),
        className: 'banner5-title kpngvypglhm-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>一站式数字化平台</p>
            <p>助力临床科研"即刻"起航</p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://www.geekedc.net/index.html',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
          target: '_blank',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image kpozkxti99-editor_css',
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/head.png',
  },
};
export const Content30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper kpmawrgnfcg-editor_css',
  },
  page: { className: 'home-page content3 kpmarnxo9x8-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>为临床科研及真实世界研究量身打造</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/solve1.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>高效</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>快速高效的方案设计</p>
                <p>原始文档拍照/语音上传</p>
                <p>历史数据导入</p>
                <p>多终端数据同步</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/solve2.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>全面</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>全媒体随访管理</p>
                <p>影像管理</p>
                <p>电子知情同意</p>
                <p>中央随机</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/solve3.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>智能</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>智能任务提醒</p>
                <p>原始文档智能电子化</p>
                <p>智能可视化分析引擎</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/solve4.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>合规</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>符合ICH-GCP、CDISC、GCDMP、CFDA等法规</p>
                <p>数据痕迹、操作痕迹记录</p>
                <p>数据版本对比</p>
                <p>AE管理与上报</p>
                <p>SOP标准管理体系</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/solve5.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>专业</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>符合CDASH标准的完整数据域</p>
                  <p>支持复杂的项目设计</p>
                  <p>全面的核查/质控体系&nbsp;</p>
                  <p>强大的全数据检索引擎</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/solve6.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>安全</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>数据隔离/数据脱敏/隐私保护</p>
                <p>访问控制/权限管理</p>
                <p>入侵检测/威胁分析/异地灾备</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper kpnljfjhk3b-editor_css',
  },
  page: { className: 'home-page content5 kpnhc6e76qd-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>应用场景</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/useScene1.png',
            className: 'kpnkyj2sb9-editor_css',
          },
          content: {
            children: (
              <span>
                <p>科室/专病数据库</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/useScene2.png',
            className: 'kpnl9w3xpxe-editor_css',
          },
          content: {
            children: (
              <span>
                <p>队列研究</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/useScene3.png',
            className: 'kpnlb9ne5o-editor_css',
          },
          content: {
            children: (
              <span>
                <p>随机对照研究</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/useScene4.png',
            className: 'kpnld4vfurf-editor_css',
          },
          content: {
            children: (
              <span>
                <p>真实世界研究</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/useScene5.png',
            className: 'kpnle2costi-editor_css',
          },
          content: {
            children: (
              <span>
                <p>患者登记</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/useScene6.png',
            className: 'kpnhv4mvuh-editor_css',
          },
          content: {
            children: (
              <span>
                <p>患者随访</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/useScene7.png',
            className: 'kpnhr3eronu-editor_css',
          },
          content: {
            children: (
              <span>
                <p>临床药物试验</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/useScene8.png',
            className: 'kpnldn8f8v9-editor_css',
          },
          content: {
            children: (
              <span>
                <p>流行病学调查</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kpndxamoi4f-editor_css',
  },
  OverPack: { className: 'home-page content6 kpnlozmllul-editor_css' },
  textWrapper: { className: 'content6-text', xs: 24, md: 12 },
  titleWrapper: {
    className: 'title-wrapper kpnct4ltpb9-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>EDC</p>
          </span>
        ),
        className: 'title-h1 kpnctukm8to-editor_css',
      },
      {
        name: 'content',
        className: 'title-content kpnctv3pswo-editor_css',
        children: (
          <span>
            <h3>新一代智能临床研究电子数据采集系统</h3>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/EDC0.png',
    className: 'content6-img kpqqh07g1rm-editor_css',
    xs: 24,
    md: 12,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/EDC1.png',
          className: 'content6-icon kpnehmwgwth-editor_css',
        },
        title: {
          className: 'content6-title kpnddooive7-editor_css',
          children: '',
        },
        content: {
          className: 'content6-content kpndsx3mmq-editor_css',
          children: (
            <span>
              <p>支持灵活的CRF表配置，快速搭建数据库</p>
            </span>
          ),
        },
        className: 'kpneie4ba1c-editor_css',
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon kpnehuj82b-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/EDC2.png',
        },
        title: {
          className: 'content6-title kpnddv5nple-editor_css',
          children: '',
        },
        content: {
          className: 'content6-content kpndwq17j9k-editor_css',
          children: (
            <span>
              <p>支持复杂研究设计，满足各种形式的方案要求</p>
            </span>
          ),
        },
        className: 'kpneiubca0p-editor_css',
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon kpnehzaeur-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/EDC3.png',
        },
        title: {
          className: 'content6-title kpnddxz9pmn-editor_css',
          children: '',
        },
        content: {
          className: 'content6-content kpndwuojlpj-editor_css',
          children: (
            <span>
              <p>内置常用CRF表及CADSH标准模板</p>
            </span>
          ),
        },
        className: 'kpnej0jwd7-editor_css',
      },
      {
        name: 'block~kpndet37cf',
        img: {
          className: 'content6-icon kpnei5nish-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/EDC4.png',
        },
        title: {
          className: 'content6-title kpnddxz9pmn-editor_css',
          children: '',
        },
        content: {
          className: 'content6-content kpndwyb6dib-editor_css',
          children: (
            <span>
              <p>提供专业的研究设计服务，医生只需提出科研需求</p>
            </span>
          ),
        },
        className: 'kpnej6f4u68-editor_css',
      },
    ],
  },
};
export const Feature41DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kpntw24ke7-editor_css',
  },
  OverPack: { className: 'home-page content6 kpnru96oakc-editor_css' },
  textWrapper: {
    className: 'content6-text kpnrvl0jng-editor_css',
    xs: 24,
    md: 12,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>中央随机系统</p>
          </span>
        ),
        className: 'title-h1 kpns0eftobi-editor_css',
      },
    ],
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/center0.png',
    className: 'content6-img kpnru1lthq-editor_css',
    xs: 24,
    md: 10,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/center1.png',
          className: 'content6-icon kpntrozpnp-editor_css',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpntsexwnx-editor_css',
          children: (
            <span>
              <p>以受试者为中心，支持多中心临床试验</p>
            </span>
          ),
        },
        className: 'kpntt3y5jhh-editor_css',
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon kpnts2d0vhd-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/center2.png',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpntujbmn8l-editor_css',
          children: (
            <span>
                <p>支持多种随机方法及随机模拟功能</p>
            </span>
          ),
        },
        className: 'kpntw6ye9ms-editor_css',
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon kpntrwfaawo-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/center3.png',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpntslgq249-editor_css',
          children: (
            <span>
              <p>智能药物流转与跟踪管理</p>
            </span>
          ),
        },
        className: 'kpnttrct43-editor_css',
      },
    ],
  },
};
export const Feature42DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kpotrjull9e-editor_css',
  },
  OverPack: { className: 'home-page content6 kposzdbo97l-editor_css' },
  textWrapper: { className: 'content6-text', xs: 24, md: 12 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
              <p>全媒体随访管理</p>
          </span>
        ),
        className: 'title-h1 kpnulu3miu-editor_css',
      },
      {
        name: 'content',
        className: 'title-content kpnurcawm2-editor_css',
        children: (
          <span>
            <p>
              解决受访者漏访、失访、难访等问题、提高受访者满意度和依从性，保证临床数据采集的连续性和完整性。
            </p>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/follow0.png',
    className: 'content6-img',
    xs: 24,
    md: 10,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/follow1.png',
          className: 'content6-icon kpnuunk77v7-editor_css',
        },
        title: {
          className: 'content6-title kpnuvpl5eqb-editor_css',
          children: (
            <span>
              <p>微信小程序</p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
        className: 'kpnuv5xoyt-editor_css',
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon kpnuuw0r6vr-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/follow2.png',
        },
        title: {
          className: 'content6-title kpnuvdkdce-editor_css',
          children: (
            <span>
              <p>公众号</p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
        className: 'kpnuve8tb2o-editor_css',
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon kpnuv0mci3h-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/follow3.png',
        },
        title: {
          className: 'content6-title kpnuwfktlul-editor_css',
          children: (
            <span>
              <p>电话呼叫中心</p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
        className: 'kpnuvlhi28p-editor_css',
      },
    ],
  },
};
export const Feature43DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kpp1qultqrg-editor_css',
  },
  OverPack: { className: 'home-page content6' },
  textWrapper: {
    className: 'content6-text kpoua6x5zz-editor_css',
    xs: 24,
    md: 13,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>电子知情同意</p>
          </span>
        ),
        className: 'title-h1 kpov9eplvu-editor_css',
      },
    ],
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/consent0.png',
    className: 'content6-img',
    xs: 24,
    md: 11,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/consent1.png',
          className: 'content6-icon kpov6ns077g-editor_css',
        },
        title: {
          className: 'content6-title kpov0q3z9f-editor_css',
          children: (
            <span>
              <p>
                通过文本、语音、视频、图片等形式向受访者介绍知情同意书要求
              </p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
        className: 'kpov8pwsk2-editor_css',
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon kpov81aik-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/consent2.png',
        },
        title: {
          className: 'content6-title kpov7rkg7w-editor_css',
          children: (
            <span>
              <p>支持知识回顾、采集签字、电子签名等功能</p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
        className: 'kpov8vpfd6b-editor_css',
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon kpov8956fn7-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/consent3.png',
        },
        title: {
          className: 'content6-title kpov7xh7bx-editor_css',
          children: (
            <span>
              <p>符合法规要求，便于受访者使用</p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
        className: 'kpov8zjiqgp-editor_css',
      },
    ],
  },
};
export const Feature44DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kpp2ob4gnmt-editor_css',
  },
  OverPack: { className: 'home-page content6 kpowmoyulxr-editor_css' },
  textWrapper: { className: 'content6-text', xs: 24, md: 12 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>数据分析与可视化展示</p>
          </span>
        ),
        className: 'title-h1 kpovpgfll5l-editor_css',
      },
    ],
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/data0.png',
    className: 'content6-img',
    xs: 24,
    md: 11,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/data1.png',
          className: 'content6-icon kpozsc9tqbc-editor_css',
        },
        title: {
          className: 'content6-title kpozt93jgh-editor_css',
          children: (
            <span>
              <p>集成多类型的分析模型及分析工具包</p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon kpozssheb3m-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/data2.png',
        },
        title: {
          className: 'content6-title kpozty5vq4o-editor_css',
          children: (
            <span>
              <p>自动生成多维度统计分析报告</p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
      },
      {
        name: 'block3',
        img: {
          className: 'content6-icon kpozswd1gt-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/data3.png',
        },
        title: {
          className: 'content6-title kpozu52yxml-editor_css',
          children: (
            <span>
              <p>支持多种医学数据统计分析服务</p>
            </span>
          ),
        },
        content: { className: 'content6-content', children: '' },
      },
    ],
  },
};
export const Feature46DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kpp65gltpw-editor_css',
  },
  OverPack: { className: 'home-page content6 kpp5pcz81og-editor_css' },
  textWrapper: {
    className: 'content6-text kpp5pi89nts-editor_css',
    xs: 24,
    md: 12,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>文档管理</p>
          </span>
        ),
        className: 'title-h1 kpp5pvb4cwh-editor_css',
      },
    ],
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/document0.png',
    className: 'content6-img',
    xs: 24,
    md: 11,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/document1.png',
          className: 'content6-icon kpp5swb2cji-editor_css',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp5t4m23x-editor_css',
          children: (
            <span>
              <p>临床研究全文档管理系统</p>
            </span>
          ),
        },
        className: 'kpp5tnbvvxi-editor_css',
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon kpp5u5pc0ms-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/document2.png',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp5tt82an-editor_css',
          children: (
            <span>
              <p>有效地保存、管理和跟踪临床研究文档</p>
            </span>
          ),
        },
        className: 'kpp5ucps9p-editor_css',
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon kpp5uifw43g-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/document3.png',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp5uwe43j-editor_css',
          children: (
            <span>
              <p>确保文档的完整、及时与准确</p>
            </span>
          ),
        },
        className: 'kpp5vehaj1n-editor_css',
      },
    ],
    className: 'kpp6b72dlbm-editor_css',
  },
};
export const Feature45DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kpp2ol15m2-editor_css',
  },
  OverPack: { className: 'home-page content6 kpp2i0c9hq7-editor_css' },
  textWrapper: { className: 'content6-text', xs: 24, md: 12 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>科研全流程项目管理</p>
          </span>
        ),
        className: 'title-h1 kpp1jo3n85b-editor_css',
      },
    ],
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/item0.png',
    className: 'content6-img',
    xs: 24,
    md: 11,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/item1.png',
          className: 'content6-icon kpp2ji0rb1q-editor_css',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp2jg7hdl-editor_css',
          children: (
            <span>
              <p>全面支持回顾性或前瞻性研究</p>
            </span>
          ),
        },
        className: 'kpp2l719fbt-editor_css',
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon kpp2jp8c2a-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/item2.png',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp2kjcqode-editor_css',
          children: (
            <span>
              <p>提供科研团队或多中心用户管理，辅以灵活的权限设置</p>
            </span>
          ),
        },
        className: 'kpp2l2w0gx-editor_css',
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon kpp2jv4m1zu-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/item3.png',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp2kpcb29-editor_css',
          children: (
            <span>
              <p>优化临床研究运营管理体系，把控临床试验质量、风险和进程</p>
            </span>
          ),
        },
        className: 'kpp2kwhrez-editor_css',
      },
    ],
  },
};
export const Feature47DataSource = {
  wrapper: {
    className: 'home-page-wrapper content6-wrapper kps1c7d36y-editor_css',
  },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 12 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>影像管理</p>
          </span>
        ),
        className: 'title-h1 kpp72m7butc-editor_css',
      },
    ],
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/image0.png',
    className: 'content6-img',
    xs: 24,
    md: 11,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/image1.png',
          className: 'content6-icon kpp76mizois-editor_css',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp78catr4f-editor_css',
          children: (
            <span>
              <p>影像全流程的规范化控制和电子化管理</p>
            </span>
          ),
        },
        className: 'kpp77xb067p-editor_css',
      },
      {
        name: 'block2',
        img: {
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/image2.png',
          className: 'content6-icon kpp76mizois-editor_css',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp78catr4f-editor_css',
          children: (
            <span>
                <p>支持多格式影像上传和存储</p>
            </span>
          ),
        },
        className: 'kpp77xb067p-editor_css',
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon kpp76zq20ql-editor_css',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/image3.png',
        },
        title: { className: 'content6-title', children: '' },
        content: {
          className: 'content6-content kpp78zjh1ug-editor_css',
          children: (
            <span>
              <p>完善的阅片流程管理，减少阅片偏移</p>
            </span>
          ),
        },
        className: 'kpp788tpgqg-editor_css',
      },
    ],
  },
};
export const Content51DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper kpqeol5rca-editor_css',
  },
  page: { className: 'home-page content5 kprsdqsqy7s-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '客户案例',
        className: 'title-h1 kprqcz4c2al-editor_css',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc8u5m5uf-editor_css',
            href: '',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client1.png',
            className: 'kpqb2zejqj-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqb0jd5hj-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client2.png',
            className: 'kpqb0nxab0g-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc7ys9199-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client3.png',
            className: 'kpqb89l194-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc7o6qk57-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client4.png',
            className: 'kpqb86t575k-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc8ql887-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client5.png',
            className: 'kpqb7qbo49-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block~kprzcgu7648',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc8mizt0g-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client6.png',
            className: 'kpqb83r47yd-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc8a3vet-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client7.png',
            className: 'kpqb25ci6sl-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block~kpryznrvfb6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc7ys9199-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client8.png',
            className: 'kpqb89l194-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc8dqtqbn-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client9.png',
            className: 'kpqb22fbygb-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc8mizt0g-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client10.png',
            className: 'kpqb83r47yd-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block~kps0xn9uj6e',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc8mizt0g-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client11.png',
            className: 'kpqb83r47yd-editor_css',
          },
          content: { children: '' },
        },
      },
      {
        name: 'block~kprzbtoonn',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kpqc8mizt0g-editor_css',
          },
          img: {
            children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/client12.png',
            className: 'kpqb83r47yd-editor_css',
          },
          content: { children: '' },
        },
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper kprrntdq2a-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: {
    className: 'content2-img kprrcdqyy7s-editor_css',
    md: 11,
    xs: 24,
  },
  img: {
    children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/about0.png',
    className: 'kprqyt2esn-editor_css',
  },
  textWrapper: {
    className: 'content2-text kprqyii4p-editor_css',
    md: 13,
    xs: 24,
  },
  title: {
    className: 'content2-title kprqy0c9trd-editor_css',
    children: (
      <span>
        <p>关于我们</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content kprroklf5fj-editor_css',
    children: (
      <span>
        <p>
          &nbsp; &nbsp; &nbsp;
          &nbsp;北京海康云翔科技有限公司是一家致力于互联网+AI赋能医疗健康研究的创新企业。以临床数据科学管理为中心，以医学智能为目标，通过自主研发的临床研究一体化平台等产品，为临床医教研工作和医药企业提供高质量的医疗数据采集、信息集成、标准化及患者随访管理等医疗健康大数据一体化解决方案。
        </p>
      </span>
    ),
  },
};
export const Footer10DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer1-wrapper kpneab17ggb-editor_css',
  },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page kpmbtxtnef-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 5,
        className: 'block',
        title: {
          className: 'logo',
          children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/hk.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [{ name: 'image1', className: '', children: '' }],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 7,
        className: 'block',
        title: {
          children: (
            <span>
              <p>联系我们</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'content_1',
              className: '',
              children: (
                <span>
                  <p>地址 ：北京市昌平区龙域中心 B 座 </p>
                </span>
              ),
            },
            {
              name: 'content_2',
              className: '',
              children: (
                <span>
                  <p>电话 ：13811309456 010-86484538</p>
                </span>
              ),
            },
            {
              name: 'content~kprqe4ap03t',
              className: '',
              children: (
                <span>
                  <p>E-mail : geekedc@163.com</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block kprx1kzde5q-editor_css',
        title: {
          children: (
            <span>
              <p>极客医研通</p>
            </span>
          ),
          className: 'kprqfy9k2uu-editor_css',
        },
        childWrapper: {
          children: [
            {
              name: 'image~kpmbsq1wonj',
              className: '',
              children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/jkyyt.png',
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block kprx1oi53d-editor_css',
        title: {
          children: (
            <span>
                <p>极客医管家</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'image~kpmbswoqo8n',
              className: '',
              children: 'https://hk-share.oss-cn-beijing.aliyuncs.com/hkyx-website/jkygj.png',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
          <a href="https://beian.miit.gov.cn/" target="_blank">
            京ICP备18050294号-2
          </a>
          <br />
      </span>
    ),
  },
};
